.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

#pdfdiv a {
    color: #0087C3;
    text-decoration: none;
}

#pdfdiv {
    position: relative;
    width: 20cm;
    /*height: 29.7cm;*/
    margin: 0 auto;
    color: #555555;
    background: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

#pdfdiv header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #AAAAAA;
}

#logo {
    float: left;
    margin-top: 8px;
    padding: 5px;
    background-color: #000;
}

#logo img {
    height: 70px;
}

#company {
    float: right;
    text-align: right;
}

#details {
    margin-bottom: 50px;
}

#client {
    padding-left: 6px;
    border-left: 6px solid #0087C3;
    float: left;
    max-width: 10cm;
}

#client .to {
    color: #777777;
}

#pdfdiv h2.name {
    font-size: 1.4em;
    font-weight: normal;
    margin: 0;
}

#invoice {
    float: right;
    text-align: right;
}

#invoice h1 {
    color: #0087C3;
    font-size: 2em;
    line-height: 1em;
    font-weight: normal;
    margin: 0 0 10px 0;
}

#invoice .date {
    font-size: 1.1em;
    color: #777777;
}

#pdfdiv table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
}

#pdfdiv table th,
#pdfdiv table td {
    padding: 20px;
    background: #EEEEEE;
    text-align: center;
    border-bottom: 1px solid #FFFFFF;
}

#pdfdiv table th {
    white-space: nowrap;
    font-weight: normal;
}

#pdfdiv table td {
    text-align: right;
}

#pdfdiv table td h3 {
    color: #57B223;
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 0.2em 0;
}

#pdfdiv table .no {
    color: #FFFFFF;
    font-size: 1.6em;
    background: #57B223;
}

#pdfdiv table .desc {
    text-align: left;
}

#pdfdiv table .unit {
    background: #DDDDDD;
}

#pdfdiv table .qty {
}

#pdfdiv table .total {
    background: #57B223;
    color: #FFFFFF;
}

#pdfdiv table td.unit,
#pdfdiv table td.qty,
#pdfdiv table td.total {
    font-size: 1.2em;
}

#pdfdiv table tbody tr:last-child td {
    border: none;
}

#pdfdiv table tfoot td {
    padding: 10px 20px;
    background: #FFFFFF;
    border-bottom: none;
    font-size: 1.2em;
    white-space: nowrap;
    border-top: 1px solid #AAAAAA;
}

#pdfdiv table tfoot tr:first-child td {
    border-top: none;
}

#pdfdiv table tfoot tr:last-child td {
    color: #57B223;
    font-size: 1.4em;
    border-top: 1px solid #57B223;

}

#pdfdiv table tfoot tr td:first-child {
    border: none;
}

#thanks {
    font-size: 2em;
    margin-bottom: 50px;
}

#notices {
    padding-left: 6px;
    border-left: 6px solid #0087C3;
}

#notices .notice {
    font-size: 1.2em;
}

#pdfdiv footer {
    color: #777777;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #AAAAAA;
    padding: 8px 0;
    text-align: center;
}

